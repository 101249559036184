// corporate/html/js/corp-2021/src/shopraise-tracking-pixel.js

// wrap in IIFE
;(function (w, d) {
  w.addEventListener('load', function(/* event */) {

    // <img src="https://shopraise.com/load-pixel-image.php?url=<encode URL>&referrer=<encoded referrer>" class="pull-left" style="margin-right: -1px;" width="1" height="1" alt="">
    
    // var referrer = encodeURIComponent(d.referrer)
    // var thisURL = encodeURIComponent(w.location.href)
    var params = {
      "url": w.location.href,
      "referrer": d.referrer,
    }
    if (typeof mixpanel !== 'undefined') {
      try {
        var mp_extra = mixpanel._.info.properties()
        var mp_search = mixpanel._.info.Lc(d.referrer)
        if (mp_extra) {
          params["$os"] = mp_extra["$os"]
          params["$browser"] = mp_extra["$browser"]
          params["$browser_version"] = mp_extra["$browser_version"]
          params["$screen_height"] = mp_extra["$screen_height"]
          params["$screen_width"] = mp_extra["$screen_width"]
        }
        if (mp_search) {
          if (mp_search["$search_engine"]) params["$search_engine"] = mp_search["$search_engine"]
          if (mp_search["mp_keyword"]) params["mp_keyword"] = mp_search["mp_keyword"]
        }
      }
      catch (e) {
        console.log('shopraise-tracking-pixel.js:: mixpanel error', e);
      }
    }
    var extraQuery = new URLSearchParams(params)
    var pixel = d.createElement('img')
    // pixel.src = '/load-pixel-image.php?url='+thisURL+'&referrer='+referrer
    pixel.src = '/load-pixel-image.php?'+extraQuery.toString()
    pixel.width = 1
    pixel.height = 1
    pixel.alt = ''
    // pixel.className = 'pull-left' # not needed for corporate pages
    pixel.style = 'margin-right: -1px;'

    d.body.appendChild(pixel);

  })
}(window, document))