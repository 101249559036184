// corporate/html/js/corp-2021/src/footer-contact-us.js

// wrap in IIFE
;(function () {

  const hamburger = document.getElementById('hamburger-button')
  if (!hamburger) return; // nothing to handle

  const menu = document.querySelector('.nav-top')
  
  function toggleHamburger() {
    menu.classList.toggle('reveal')
  }

  // hamburger click
  hamburger.addEventListener('click', toggleHamburger)
  
}())
